/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import ToastHelper from '@launchpad/util/ToastHelper';
import UsersModel from '../models/UsersModel';

const UserAdditionalActions = ({ userId, directUserRoles }) => {
  const [loadingCardsAndAccounts, setLoadingCardsAndAccounts] = useState(true);
  const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);
  const [loadingIssueCard, setLoadingIssueCard] = useState(false);
  const [allowIssueCard, setAllowIssueCard] = useState(true);
  const [allowCreateAccount, setAllowCreateAccount] = useState(true);
  const hasUserWoCapacity = directUserRoles?.some(
    r => r.role && r.role.name === 'user-wo-capacity'
  );
  const userSupporter = directUserRoles?.some(
    r =>
      (r.role && r.role.name === 'lpa-supporter') || r.role.name === 'supporter'
  );

  useEffect(() => {
    fetchCardsAndAccounts();
  }, [userId]);

  const fetchCardsAndAccounts = async () => {
    setLoadingCardsAndAccounts(true);
    await new UsersModel()
      .fetchCardsAndAccounts(userId)
      .then(result => {
        const { accountsResult, cardsResult } = result;
        const hasEWallet = accountsResult.find(
          acc => acc.type === 'CURRENT_ACCOUNT'
        );
        const hasCard = cardsResult.length > 0;

        if (hasEWallet && !hasCard) {
          setAllowCreateAccount(false);
        }
        if (hasCard && !hasEWallet) {
          setAllowIssueCard(false);
        }
        if (hasCard && hasEWallet) {
          setAllowCreateAccount(false);
          setAllowIssueCard(false);
        }
      })
      .finally(() => {
        setLoadingCardsAndAccounts(false);
      });
  };

  const manuallyCreateEwallet = async () => {
    setLoadingCreateAccount(true);
    await new UsersModel()
      .manuallyCreateEwallet(userId)
      .then(async result => {
        if (result.success) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          await fetchCardsAndAccounts();
          ToastHelper.show(
            `You have successfully created an account for the user ${userId}`,
            'success'
          );
          setAllowCreateAccount(false);
        } else {
          ToastHelper.show('Something went wrong', 'error');
        }
      })
      .catch(() => {
        ToastHelper.show('Something went wrong', 'error');
      })
      .finally(() => {
        setLoadingCreateAccount(false);
      });
  };

  const manuallyIssuePaymentDevice = async () => {
    setLoadingIssueCard(true);
    await new UsersModel()
      .manuallyIssuePaymentDevice(userId)
      .then(async result => {
        if (result.success) {
          await new Promise(resolve => setTimeout(resolve, 2000));
          await fetchCardsAndAccounts();
          setAllowIssueCard(false);
          ToastHelper.show(
            `You have successfully ordered a card for the user ${userId}`,
            'success'
          );
        } else {
          ToastHelper.show('Something went wrong', 'error');
        }
      })
      .catch(() => {
        ToastHelper.show('Something went wrong', 'error');
      })
      .finally(() => {
        setLoadingIssueCard(false);
      });
  };

  return userSupporter || loadingCardsAndAccounts ? null : (
    <div style={{ flexDirection: 'column', display: 'flex' }}>
      {hasUserWoCapacity && allowCreateAccount && (
        <DeleteButton
          btnText={loadingCreateAccount ? 'Loading...' : 'Create account'}
          onConfirm={() => manuallyCreateEwallet()}
          text={`Are you sure you wish to create account for user ${userId}?`}
          style={{ minWidth: 200 }}
          className={
            loadingCreateAccount ? 'disabled button-success' : 'button-success'
          }
        />
      )}
      {allowIssueCard && (
        <DeleteButton
          btnText={loadingIssueCard ? 'Loading...' : 'Issue a card'}
          onConfirm={() => manuallyIssuePaymentDevice()}
          text={`Are you sure you want to issue a card for the user ${userId}?`}
          style={{ minWidth: 200, marginTop: 10 }}
          className={
            loadingIssueCard ? 'disabled button-success' : 'button-success'
          }
        />
      )}
    </div>
  );
};

export default UserAdditionalActions;
