import BaseModel from '@launchpad/logic/model/BaseModel';
import { OverrideService } from '@launchpad/logic/services';

export default class UploadDocumentModel extends BaseModel {
  getSubmitUrl() {
    return `admin/${OverrideService.getConfig().pluginName ||
      process.env.REACT_APP_ADMIN_VARIANT}/onboarding/upload-document/${
      this.data.userId
    }`;
  }

  getCustomHeaders() {
    return {
      'Content-Type': 'multipart/form-data'
    };
  }
}
