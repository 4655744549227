/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ToastHelper from '@launchpad/util/ToastHelper';
import { withRouter } from 'react-router';
// import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { date } from '@launchpad/util/DateHelper';
import UsersModel from '../../models/UsersModel';
import { LoadingOverlay } from '../../../../../../@launchpad/index';
import UploadDocumentModal from '../../edit/UploadDocumentModal';

class KycInfo extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      redirect: false,
      isLoading: false,
      identificationCreated: false,
      KYCDocuments: [],
      showUploadDocumentModal: false
    };
  }

  componentDidMount() {
    this._pullKYCDocuments();
    this.pullIdentificationInfo();
  }

  onChangeUserStatusKyc(id, status) {
    this.setState({ isLoading: true });

    if (status === 'UNVERIFIED') {
      // Custom handle when admin restarts status to unverified
      new UsersModel()
        .resetKycDocuments(id)
        .then(data => {
          this.props.onRefresh();
          if (data.createdIdentificationExists) {
            ToastHelper.show(
              'User already has identification, please contact KYC provider to remove it.',
              'info'
            );
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => this.setState({ isLoading: false }));
    } else {
      new UsersModel()
        .changeUserStatusKYC(id, status)
        .then(() => {
          this.props.onRefresh();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => this.setState({ isLoading: false }));
    }
  }

  async _pullKYCDocuments() {
    const { id } = this.props.match.params;

    await new UsersModel().getKycDocument(id).then(result => {
      if (result?.documents) {
        this.setState({
          KYCDocuments: result.documents
        });
      }
    });
  }

  async pullIdentificationInfo() {
    const { id } = this.props.match.params;

    await new UsersModel().getIdentification(id).then(result => {
      if (result) {
        this.setState({
          identificationCreated: result.createdIdentificationExists
        });
      }
    });
  }

  async triggerIdentification() {
    const { id } = this.props.match.params;

    await new UsersModel()
      .triggerIdentification(id)
      .then(result => {
        if (result.success) {
          ToastHelper.show('Identification triggered', 'success');
        } else {
          ToastHelper.show('Something went wrong', 'error');
        }
      })
      .catch(() => {
        ToastHelper.show('Something went wrong', 'error');
      });
  }

  // async removeIdentification() {
  //   const { id } = this.props.match.params;

  //   await new UsersModel().removeIdentification(id).then(result => {
  //     if (result) {
  //       console.log('RES:', result);
  //     }
  //   });
  // }

  render() {
    const { user } = this.props;

    if (this.state.redirect) {
      return <Redirect to={`/user-details/${user.id}`} />;
    }
    const kycStatus = user.kycStatus || '';
    const newKycStatus = kycStatus.replace(/#|_/g, ' ');
    const userKycStatus = user.kycStatus ? user.kycStatus.toUpperCase() : '';
    return (
      <div className="main" key={user}>
        <div className="dark-blur" />
        <div className="customers-info">
          <div className="customer-details">
            <div>
              <h2 className="customer-name">
                <span className="company">
                  {user.firstName} {user.lastName}
                </span>
              </h2>
            </div>
          </div>

          <div className="customers-main">
            <div className="row">
              <div className="col">
                {!user || this.state.isLoading ? (
                  <LoadingOverlay />
                ) : (
                  <div className="padded">
                    <div className="row text-white">
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <dl>
                          <dt>Change KYC status:</dt>
                          <dd>
                            <select
                              className="option-wrapper"
                              onChange={event =>
                                this.onChangeUserStatusKyc(
                                  user.id,
                                  event.target.value
                                )
                              }
                              value={
                                userKycStatus !== 'UNVERIFIED'
                                  ? userKycStatus
                                  : ''
                              }
                            >
                              <option disabled value="">
                                -- select an option --
                              </option>
                              {/* <option value="PENDING_DATA_RESUBMISSION">
                                Request re-entry of personal details
                              </option> */}
                              {user.kycStatus?.toLowerCase() !==
                                'unverified' && (
                                <option value="UNVERIFIED">
                                  Request re-entry of KYC documents
                                </option>
                              )}
                              <option value="VERIFICATION_IN_PROGRESS">
                                Verification in progress
                              </option>
                              <option value="VERIFIED">Verified</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Identification created:</dt>
                          <dd>
                            {this.state.identificationCreated ? 'Yes' : 'No'}
                          </dd>
                        </dl>
                        {!this.state.identificationCreated && (
                          <dl>
                            <dd style={{ float: 'right' }}>
                              <button
                                style={{ width: 180 }}
                                type="button"
                                className="button-info btn-block"
                                onClick={() => this.triggerIdentification()}
                              >
                                Trigger identification
                              </button>
                            </dd>
                          </dl>
                        )}
                        {/* {this.state.identificationCreated &&
                          user.kycStatus.toLowerCase() === 'unverified' && (
                            <dl>
                              <dt>
                                Remove identification in order to allow user to
                                retry kyc flow:
                              </dt>
                              <dd>
                                <DeleteButton
                                  btnText="Remove identification"
                                  onConfirm={() => this.removeIdentification()}
                                  text="Are you sure you wish to delete identification on kyc vendor?"
                                />
                              </dd>
                            </dl>
                          )} */}
                      </div>
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="mb-0">KYC Documents Submission</h4>
                          <>
                            <div
                              onClick={() =>
                                this.setState({
                                  showUploadDocumentModal: true
                                })
                              }
                              className="button"
                            >
                              <i className="fa fa-fw fa-plus" />
                              Upload documents
                            </div>
                            <UploadDocumentModal
                              key={this.state.showUploadDocumentModal}
                              showModal={this.state.showUploadDocumentModal}
                              onClose={() => {
                                this.setState({
                                  showUploadDocumentModal: false
                                });
                                this._pullKYCDocuments();
                              }}
                            />
                          </>
                        </div>
                        <hr />
                        <div className="d-flex flex-column">
                          {this.state.KYCDocuments.length ? (
                            this.state.KYCDocuments.map((item, index) => {
                              return (
                                <div
                                  className="d-flex flex-column"
                                  key={`KYCDocument${index}`}
                                >
                                  <span>Type: {item.type}</span>
                                  <span>
                                    Created At:{' '}
                                    {date(item.createdAt, 'DD-MM-Y HH:mm')}
                                  </span>
                                  <span>Status: {item.status}</span>
                                  <div className="d-flex align-items-center justify-content-center ">
                                    <img
                                      key={`document${index}`}
                                      src={item.publicUrl}
                                      style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: 300,
                                        margin: 20
                                      }}
                                      alt="kyc-document"
                                    />
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <h4 className="m-3">No KYC documents</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(KycInfo);
