/* eslint-disable max-classes-per-file */
import React from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad';
import BaseModel from '@launchpad/logic/model/BaseModel';
import AppSelect from '@launchpad/components/crud/AppSelect';
import CountryModel from '@launchpad/logic/model/CountryModel';
import ToastHelper from '@launchpad/util/ToastHelper';

export default class EditAddressForm extends React.Component {
  formHandler;

  constructor(props) {
    super(props);
    this.formHandler = new FormHandler(
      this,
      new EditAddressModel({
        id: props.user.id,
        addressOne: props.user.addressOne,
        addressTwo: props.user.addressTwo,
        city: props.user.city,
        postCode: props.user.postCode,
        countryCode: props.user.countryCode
      })
    );
  }

  _onSuccess() {
    this.props.close();
    this.props.onConfirm();
    ToastHelper.show(
      `You have successfully changed the address for the user ${this.props.user.email}`,
      'success'
    );
  }

  _getActionButtons(loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20
        }}
      >
        <button
          type="button"
          onClick={this.props.close}
          className="button-danger"
        >
          Cancel
        </button>
        <button type="submit" className="button-info" style={{ minWidth: 55 }}>
          {loading ? (
            <CircularProgress className="circle-progress" size={20} />
          ) : (
            'Submit'
          )}
        </button>
      </div>
    );
  }

  render() {
    const { formHandler } = this;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p style={{ textAlign: 'center', paddingBottom: 20 }}>
          Please provide new address information
        </p>
        <div style={{ width: '50%', marginBottom: 20 }}>
          <FormSubmitWrapper
            formHandler={formHandler}
            renderActionButtons={loading => this._getActionButtons(loading)}
            onSuccess={() => this._onSuccess()}
          >
            <InputField
              materialProps={{
                fullWidth: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              customInput={TextField}
              label="Address Line One"
              type="text"
              name="addressOne"
              value={this.state.record.addressOne}
              handler={formHandler}
            />
            <InputField
              materialProps={{
                fullWidth: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              customInput={TextField}
              label="Address Line Two"
              type="text"
              name="addressTwo"
              value={this.state.record.addressTwo}
              handler={formHandler}
            />
            <InputField
              materialProps={{
                fullWidth: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              customInput={TextField}
              label="City"
              type="text"
              name="city"
              value={this.state.record.city}
              handler={formHandler}
            />
            <InputField
              materialProps={{
                fullWidth: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              customInput={TextField}
              label="Post code"
              type="text"
              name="postCode"
              value={this.state.record.postCode}
              handler={formHandler}
            />
            <div className="input-wrap-country">
              <AppSelect
                textFieldProps={{
                  label: 'Country',
                  InputLabelProps: {
                    shrink: true
                  }
                }}
                model={CountryModel}
                getOptionValue={country => country.iso3}
                name="countryCode"
                value={this.state.record.countryCode}
                handler={formHandler}
                placeholder="Search a Country"
              />
            </div>
          </FormSubmitWrapper>
        </div>
      </div>
    );
  }
}

class EditAddressModel extends BaseModel {
  getSubmitUrl() {
    return `admin/profile/${this.data.id}`;
  }

  getSubmitMethod() {
    return 'PUT';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      addressOne: {
        presence: {
          message: '^Please enter address line one'
        }
      },
      addressTwo: {
        presence: {
          message: '^Please enter address line two'
        }
      },
      city: {
        presence: {
          message: '^Please enter city'
        }
      },
      postCode: {
        presence: {
          message: '^Please enter post code'
        }
      },
      countryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country'
        }
      }
    };
  }
}
