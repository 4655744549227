import { IdButton } from '@launchpad/components/crud/AppTable';
import React, { ReactElement } from 'react';

type UserRole = {
  id: string;
  role: {
    id: string;
    name: string;
    slug: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  entity?: string;
  entityId?: string;
};

const printRow = (row: UserRole) => {
  return (
    <tr>
      <td>
        <IdButton value={row.id} />
      </td>
      <td>{row.role.name}</td>
      <td>{printEntity(row)}</td>
    </tr>
  );
};

const printOtherRoleRow = (row: UserRole) => {
  return (
    <tr>
      <td>
        <IdButton value={row.id} />
      </td>
      <td>{row.role.name}</td>
      <td>
        <a className="userCardLink" href={`/user-details/${row.user.id}`}>
          {[row.user.firstName, row.user.lastName].filter(Boolean).join(' ')}
        </a>
      </td>
    </tr>
  );
};

const printEntity = (row: UserRole) => {
  switch (row.entity?.toUpperCase()) {
    case 'USER':
      return (
        <a className="userCardLink" href={`/user-details/${row.entityId}`}>
          User: {row.entityId}
        </a>
      );
    case 'ACCOUNT':
      return (
        <a className="userCardLink" href={`/accounts/details/${row.entityId}`}>
          Account: {row.entityId}
        </a>
      );
    case 'PAYMENT_DEVICE':
      return (
        <a className="userCardLink" href={`/cards/details/${row.entityId}`}>
          Payment Device: {row.entityId}
        </a>
      );
    default:
      return '-';
  }
};

const DirectUserRoles = (props: { userRoles: UserRole[] }): ReactElement => {
  return (
    <div>
      <h5>User owned roles</h5>
      <table className="table" style={{ width: '100%' }}>
        <tbody>
          <tr className="tr-dark-bg">
            <th style={{ color: 'white' }}>Id</th>
            <th style={{ color: 'white' }}>Role</th>
            <th style={{ color: 'white' }}>On</th>
          </tr>
          {props.userRoles && props.userRoles.length > 0 ? (
            <>
              {props.userRoles.map(r => (
                <React.Fragment key={r.id}>{printRow(r)}</React.Fragment>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={3}>
                <em>- no items -</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const OtherRoles = (props: { userRoles: UserRole[] }): ReactElement => {
  return (
    <div>
      <h5>Other roles related to user</h5>
      <table className="table" style={{ width: '100%' }}>
        <tbody>
          <tr className="tr-dark-bg">
            <th style={{ color: 'white' }}>Id</th>
            <th style={{ color: 'white' }}>Role</th>
            <th style={{ color: 'white' }}>Owner</th>
          </tr>
          {props.userRoles && props.userRoles.length > 0 ? (
            props.userRoles.map(r => printOtherRoleRow(r))
          ) : (
            <tr>
              <td colSpan={3}>
                <em>- no items -</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const UserRolesSummary = ({ directUserRoles, otherRoles }): ReactElement => {
  return (
    <div>
      <h4>Related roles</h4>
      <hr />
      <DirectUserRoles userRoles={directUserRoles} />
      <OtherRoles userRoles={otherRoles} />
    </div>
  );
};

export default UserRolesSummary;
