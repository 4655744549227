/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { FormHandler, FormSubmitWrapper, ModalDialog } from '@launchpad';
import UploadKycDocument from '@launchpad/modules/lplite/organisations/modals/upload-kyc-document/upload-kyc-document.component';
import { withRouter } from 'react-router-dom';
import UploadDocumentModel from '../models/UploadDocumentModel';

class UploadDocumentModal extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UploadDocumentModel({
        file: null,
        documentType: '',
        userId: props.match.params.id
      })
    );
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState(prevState => ({
      record: {
        ...prevState.record,
        userId: id
      }
    }));
  }

  onSubmitSuccess() {
    this.props.onClose();
    this.setState(prevState => ({
      record: {
        ...prevState.record,
        file: null,
        documentType: ''
      }
    }));
  }

  getSubmitData(record) {
    const data = new FormData();
    data.append('type', record.documentType);
    data.append('file', record.file);
    return data;
  }

  render() {
    const { formHandler } = this;
    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
      >
        <div>
          <h3>Upload document</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  buttonText="Submit"
                  customCloseFunction={this.props.onClose}
                  disabled={
                    !this.state.record.documentType || !this.state.record.file
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <span style={{ marginBottom: 5 }}>Document type:</span>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 20
                      }}
                    >
                      <select
                        className="option-wrapper"
                        onChange={event => {
                          const { value } = event.target;
                          this.setState(prevState => ({
                            record: {
                              ...prevState.record,
                              documentType: value
                            }
                          }));
                        }}
                        value={this.state.record.documentType}
                      >
                        <option disabled value="">
                          -- select an option --
                        </option>
                        {this.props.documentTypes?.length > 0 ? (
                          this.props.documentTypes.map(type => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))
                        ) : (
                          <>
                            <option value="POA">Proof of address</option>
                            {/* <option value="OTHER">Other</option> */}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  {this.state.record.documentType && (
                    <>
                      <UploadKycDocument
                        allowPdf
                        onSelectFile={file =>
                          formHandler.handleInputChange('file', file)
                        }
                        onClearFile={() =>
                          formHandler.handleInputChange('file', null)
                        }
                      />
                    </>
                  )}
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

export default withRouter(UploadDocumentModal);
